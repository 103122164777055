import axios from "axios"

export default class AqsTemplateService {
  pageSize: number = 20

  getAqsTemplates(
    page: number,
    sort: string,
    organizationId?: string,
    computerId?: string,
    userId?: string,
    species?: string,
    pageSize?: number
  ) {
    let queryParams = new URLSearchParams({
      size: (pageSize ?? this.pageSize).toString(),
      page: page.toString(),
      sort,
    })

    if (organizationId) queryParams.append("organizationId", organizationId)
    if (computerId) queryParams.append("computerId", computerId)
    if (userId) queryParams.append("userId", userId)
    if (species) queryParams.append("species", species)

    const url = `aqstemplates/support?${queryParams.toString()}`
    return axios.get(url, { baseURL: process.env.VUE_APP_VSOL_API }).then((res) => res.data)
  }

  deleteAqsTemplate(aqsTemplateId: string) {
    const url = "aqstemplates/" + aqsTemplateId
    return axios.delete(url, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  copyAqsTemplates(
    aqsTemplateIds: Array<string>,
    organizationId: string,
    computerId: string,
    userId: string
  ) {
    let queryParams = new URLSearchParams()

    if (organizationId) queryParams.append("organizationId", organizationId)
    if (computerId) queryParams.append("computerId", computerId)
    if (userId) queryParams.append("userId", userId)

    const url = `aqstemplates/copy/support?${queryParams.toString()}`
    return axios.post(url, aqsTemplateIds, { baseURL: process.env.VUE_APP_VSOL_API })
  }
}
